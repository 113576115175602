<template>
    <modal ref="modalEditarAfiliado" titulo="Editar afiliado" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="onSubmit">
        <ValidationObserver ref="form">
            <div class="row mx-0 px-4">
                <div class="col-12 border bg-fondo text-general d-middle mb-4 br-12 py-2">
                    <img :src="_.defaultTo(avatar, '/img/no-imagen/sin_user.png')" class="obj-cover rounded-circle" width="32" height="32" />
                    <div class="col-auto f-17 f-500">
                        {{ nombre_cliente }}
                    </div>
                    <div class="col-auto f-17 ml-3 f-500">
                        Código: {{ $route.params.id_cliente }}
                    </div>
                </div>
                <div class="col-6 pl-0">
                    <ValidationProvider v-slot="{errors}" rules="numeric" vid="documento" name="documento">
                        <label>Documento</label>
                        <el-input v-model="documento" size="small" class="w-100" show-word-limit maxlength="10" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-6 pr-0">
                    <label>Fecha de vinculación a la empresa</label>
                    <el-date-picker
                    v-model="fecha"
                    size="small"
                    class="w-100"
                    format="dd MMM yyyy"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    />
                </div>
                <div class="col-6 mt-3 pl-0">
                    <ValidationProvider v-slot="{errors}" rules="numeric|max_value:16777215" vid="salario" name="salario">
                        <label>Salario</label>
                        <money v-model="salario" class="input-money" v-bind="money" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-6 mt-3 pr-0 disabled">
                    <ValidationProvider v-slot="{errors}" rules="numeric|max_value:16777215" vid="cupo" name="cupo">
                        <label :class="{'disabled-div': ilimitado}">Cupo de crédito</label>
                        <money v-model="cupo" :disabled="ilimitado" class="input-money" v-bind="money" :class="{'disabled-div': ilimitado}" />
                        <el-checkbox v-model="ilimitado" class="check-dark mt-2" @change="onChangeCheckbox()">
                            Ilimitado
                        </el-checkbox>
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <el-upload
            v-if="!archivo"
            ref="adjuntos"
            class="upload-demo w-100"
            action="#"
            :multiple="false"
            :limit="1"
            :on-change="agregarArchivos"
            :auto-upload="false"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/*, application/pdf"
            >
                <div class="upload-documents w-100 text-center py-3 bg-light-f5 br-5 cr-pointer pt-1">
                    Subir PDF o Imagen
                </div>
            </el-upload>
            <div v-else class="row mx-0 px-4 mt-3">
                <div class="card-file mr-2">
                    <div class="space-icon br-l-12">
                        <i class="icon-cedis f-20" />
                    </div>
                    <div class="col text-black tres-puntos f-12">
                        {{ documentoLabel }}
                    </div>
                </div>
                <div class="btn-delete-file cr-pointer" @click="eliminarArchivo()">
                    <i class="icon-delete-trash f-20" />
                </div>
                <div class="btn-download-file cr-pointer ml-2" @click="download()">
                    <i class="icon-dowload-arrow f-20" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import detalleAfiliado from '~/services/nomina/detalleAfiliado'


export default {
    components:{
        Money,
    },
    data(){
        return{
            nombre_cliente: '',
            documento: '',
            documentoLabel: '',
            fecha: '',
            salario: '',
            cupo: '',
            ilimitado: false,
            idNominaCliente: 0,
            avatar: null,
            money: {
                thousands: '.',
                prefix: '$',
                precision: 0,
                maxlength: 11,
                masked: false   
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > new Date();
                }
            },
            archivo: null,
            fileList: [],
            documentoTipo: 1,
            modified: false,
        }
    },
    methods: {
        toggle(data){
            this.limpiar()
            const newData = _.cloneDeep(data)
            this.documento = newData.documento
            this.fecha = newData.fecha
            this.cupo = newData.cupo
            this.salario = newData.salario
            this.idNominaCliente = newData.id
            this.nombre_cliente = newData.nombre_cliente
            this.avatar = newData.avatar
            this.documentoLabel = newData.documentoLabel
            this.archivo = newData.archivo
            this.$refs.modalEditarAfiliado.toggle();
        },
        onChangeCheckbox(){
            if (this.ilimitado){
                this.cupo = 0
            }
        },
        async agregarArchivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 1
                tamano = 20
                this.documentoTipo = 2
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
                this.documentoTipo = 1
            } else if (file.raw.type === 'video/mp4'){
                tipo = 3
                tamano = 20
            } else {
                tipo = 4
                tamano = 25
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                return this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
            }

            if (tipo == 3 || tipo == 4){
                fileList.splice(fileList.indexOf(file), 1)
                return this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                return this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
            }

            this.modified = true
            this.archivo = file.raw
            
        },
        handleRemove(file, fileList){
            this.archivo = null
            this.fileList = []
            this.$refs.adjuntos.clearFiles()
        },
        async onSubmit(){
            try {
                const params = {
                    id_nomina_cliente: this.idNominaCliente,
                    cedula: this.documento,
                    salario: this.salario,
                    fecha_vinculacion: this.fecha,
                    documento: this.archivo,
                    modified: this.modified,
                    documento_tipo: this.archivo == null ? null : this.documentoTipo,
                }
                let formData = this.crear_formData(params)

                const { data } = await detalleAfiliado.actualizarAfiliado(formData)
                if(data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')  
                    this.$emit('updated')                
                    this.$refs.modalEditarAfiliado.toggle();

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        download(){
            window.open(this.archivo, 'download')
        },
        eliminarArchivo(){
            this.archivo = null
        },
        limpiar(){
            this.nombre_cliente = ''
            this.documento = ''
            this.documentoLabel = ''
            this.fecha = ''
            this.salario = ''
            this.cupo = ''
            this.ilimitado = false
            this.idNominaCliente = 0
            this.avatar = null
            this.archivo = null
            this.fileList = []
            this.documentoTipo = 1
            this.modified = false
        }
        
    }
}
</script>
<style lang="scss" scoped>
label{
    padding-left: 10px;
    font-size: 12px;
}

.card-file{
    width: 312px;
    height: 60px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-icon{
        width: 48px;
        height: 100%;
        display: flex;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background-color: #DFE4E8;
        align-items: center;
        justify-content: center;
    }
}

.btn-delete-file{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF5158;
    color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-download-file{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #29D884;
    color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>